import {
    configure,
    computed,
    observable,
    when,
    makeObservable,
    reaction,
} from 'mobx';
import React from 'react';
import {toJS} from 'mobx';

import http from 'http.js';
import analytics, {analyticsConfig} from 'analytics.js';

import {ConcatenatedTextV2, LinkV2} from '@HealthShareAU/hs-component-library';

import autobind from 'common/decorators/autobind.js';
import Store from 'core/stores/Store.js';
import {Mh1BookingApi} from 'core/services/Mh1Service';

import CallNowBtn from 'core/components/v2/CallNowBtn.js';
import MH1BookNowBtn from 'core/components/v2/MH1BookNowBtn.js';
import RequestApptBtn from 'core/components/v2/RequestApptBtn.js';
import CustomBookingBtn from 'core/components/v2/CustomBookingBtn.js';
import {HSReferralButtonV2} from 'professional/components/v2/ReferralButtonsV2.js';

import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import {convertToDayNames, toggleLoader} from 'core/utils.js';
import {
    trackPracticeLocationFaxClick,
    trackOpenPracticeLocationWebsite,
} from '../services/internalEventTracking';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed'});

const {locationSection: gaConfig} = analyticsConfig.pages.practice;

export default class PracticeStoreV2 extends Store {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;

        makeObservable(this, {
            activeTab: observable,
            customModalProps: observable,
            displayedModal: observable,
            gapSchemeMembership: observable,
            url: observable,
            practice: observable,
            practiceFAQs: observable,
            practiceRelatedContent: observable,
            practiceLoaded: observable,

            displayTabsOnPage: computed,

            // For redesign
            displayContactModal: observable,

            bookingsTab: computed,
            chips: computed,
            collapseSections: computed,
            collapseSectionsHeader: computed,
            locationSectionActiveItem: computed,
            practiceTabs: computed,
            pills: computed,
            loadFees: computed,
            availabilitySummary: observable,
            isMh1Running: observable,
        });

        when(
            () => this.url,
            () => this.fetchPracticeInfo(),
        );
        when(
            () => this.shouldFetchRelatedContent && this.url,
            () => this.fetchPracticeRelatedContent(),
        );
        when(
            () => this.shouldFetchFAQs && this.url,
            () => this.fetchPracticeFAQs(),
        );
        when(
            () => this.practiceLoaded,
            () => this.trackPageOpen(),
        );

        reaction(
            () =>
                !this.isHospitalPage &&
                this.practiceLoaded &&
                this.rootStore?.healthFundStore?.healthFund?.id,
            (healthFundId) => {
                this.fetchGapSchemeMembership(healthFundId);
            },
        );
        reaction(
            () => this.practiceLoaded,
            (loaded) => {
                toggleLoader(loaded);
            },
        );
    }

    // New tabs for redesign
    locationTab = {
        title: 'Location',
        'hash': 'location',
        'component': 'LocationSection',
    };

    galleriesTab = {
        title: 'Galleries',
        'hash': 'galleries',
        component: 'GalleriesSection',
    };

    aboutTab = {
        title: 'About',
        hash: 'about',
        component: 'AboutSection',
    };

    relatedInfoTab = {
        title: 'Related info',
        hash: 'related-info',
        component: 'PracticeRelatedInfoV2',
    };
    faqTab = {
        title: 'FAQ',
        hash: 'faq',
        component: 'PracticeFAQ',
    };
    ratingsTab = {
        title: 'Reviews',
        'hash': 'reviews',
        component: 'ReviewsSection',
    };
    feesTab = {
        title: 'Fees',
        hash: 'fees',
        component: 'FeesSection',
    };

    qnaTab = {
        title: 'Q&A',
        hash: 'questions-and-answers',
        component: 'QuestionsAndAnswersSection',
    };

    bookingTabTitle = 'Practitioners';
    avatarPlaceholderIcon = 'building';

    activeTab = null;
    client = undefined;
    customModalProps = {};
    displayedModal = undefined;
    gapSchemeMembership = [];

    url = null;
    practice = {};
    practiceFAQs = [];
    practiceRelatedContent = {};
    practiceLoaded = false;
    shouldFetchRelatedContent = true;
    shouldFetchFAQs = true;

    displayToolsOnPage = true;

    // V2 variables
    displayContactModal = false;
    availabilitySummary = [];
    isMh1Running = true;

    @autobind
    closeModal() {
        this.updateStore({
            customModalProps: {},
            displayedModal: null,
        });
    }

    @autobind
    showModal(modalType, modalProps) {
        this.updateStore({
            customModalProps: modalProps,
            displayedModal: modalType,
        });
    }

    @autobind
    getBookingAvailability(bookingIntegration) {
        let availability;
        if (this.availabilitySummary?.length) {
            availability =
                this.availabilitySummary.find(
                    (d) => d.id === bookingIntegration.bookingId,
                ) || false;
        }
        return availability;
    }

    @autobind
    async fetchGapSchemeMembership(healthFundId) {
        if (typeof healthFundId === 'undefined' || healthFundId === 0) {
            this.updateStore({gapSchemeMembership: []});
            return;
        }
        let data =
            (await http.post({
                url: `/api/professionals/v1/gap-scheme-membership/health-fund/${healthFundId}`,
                data: {
                    userprofile_ids: !!this.userId
                        ? [this.practice.id]
                        : this.practitionerIds,
                }, // When called from a UserProfile page practice.id==>UserProfile.id
            })) || [];
        data = data.map((d) => snakeToCamelObjectKeys(d));
        this.updateStore({gapSchemeMembership: data});
    }

    get practitionerIds() {
        return this.practice.practitioners?.map((pr) => pr.userprofileId);
    }

    get displayTabsOnPage() {
        return true;
    }

    get bookingsTab() {
        const {isReferrals, isProUser} = this.rootStore.paramStore;

        let title = 'Book an appointment';
        if (isReferrals || isProUser) {
            title = this.bookingTabTitle;
        }

        return {
            title,
            hash: 'book-an-appointment',
            component: 'BookingSection',
        };
    }

    get practiceTabs() {
        const displayTabs = [];
        if (this.practiceLoaded) {
            const practice = toJS(this.practice);

            let isShowGalleriesTab = false;

            const {description, images, videos} = practice;

            if (!this.rootStore.paramStore.isClient) {
                if (images?.length > 0 || videos?.length > 0) {
                    isShowGalleriesTab = true;
                }
            }

            // booking section pass
            if (this.hasPractitioners || practice.locations?.length > 0) {
                displayTabs.push(this.bookingsTab);
            }

            // About section pass
            if (description) {
                displayTabs.push(this.aboutTab);
            }

            if (this.hasFees) {
                displayTabs.push(this.feesTab);
            }

            // location pass
            if (this.shouldShowLocationsTab) {
                displayTabs.push(this.locationTab);
            }

            // Questions and answers
            if (!!this.practice.id && this.totalContributions) {
                displayTabs.push(this.qnaTab);
            }

            // Reviews
            if (this.practice.ratings) {
                displayTabs.push(this.ratingsTab);
            }

            // galleries
            if (isShowGalleriesTab) {
                displayTabs.push(this.galleriesTab);
            }

            // FAQ
            if (this.practiceFAQs.length) {
                displayTabs.push(this.faqTab);
            }
            // related info (ignoring communities for now)
            if (
                (!this.rootStore.paramStore.isClient &&
                    !this.practice.enhanced &&
                    !this.practice.premiumPartner && //Factsheets for UserProfile and PracticeLocation
                    (this.relatedFactsheets?.length > 0 ||
                        !!this.practiceRelatedContent)) ||
                this.practice.files?.length > 0 ||
                this.practice.registrationNumbers
            ) {
                // If in UserProfile and no registrationNumbers, existing relatedFactsheets and skinned, do not load the tab.
                if (!!this.userId) {
                    if (!!this.practice.registrationNumbers) {
                        displayTabs.push(this.relatedInfoTab);
                    } else if (
                        this.relatedFactsheets?.length > 0 &&
                        !this.rootStore.paramStore.hasSkin
                    ) {
                        displayTabs.push(this.relatedInfoTab);
                    }
                    // As PracticeLocation doesn't have registrationNumbers, we only load the tab if not skinned and have documents
                } else if (
                    !!this.practiceRelatedContent ||
                    this.practice.files?.length > 0
                ) {
                    if (this.practice.files?.length > 0) {
                        displayTabs.push(this.relatedInfoTab);
                    } else if (
                        !this.rootStore.paramStore.hasSkin &&
                        !!this.practiceRelatedContent
                    ) {
                        displayTabs.push(this.relatedInfoTab);
                    }
                }
            }
        }
        return displayTabs;
    }

    get locationSectionActiveItem() {
        return this.practice;
    }

    get chips() {
        const {
            paramStore: {hasSkin},
            healthFundStore: {healthFundThemeColor},
        } = this.rootStore;
        const {latitude, longitude} = this.locationSectionActiveItem;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;
        const {
            externalUrl,
            fax,
            practiceLocationAddress,
            phones,
        } = this.locationSectionChipsData;
        const practiceId = this.practice.id;
        const practicePositionId = this.locationSectionActiveItem.id;
        const validAddress = latitude !== 0 && longitude !== 0;
        const chips = [];
        if (practiceLocationAddress) {
            const googlePreviewUrl = `https://www.google.com/maps/preview/?q=${practiceLocationAddress}`;
            chips.push({
                icon: 'markerPin',
                text: practiceLocationAddress,
                children: validAddress ? (
                    <LinkV2
                        colorTheme={colorTheme}
                        onClick={() => {}}
                        target="_blank"
                        text={practiceLocationAddress}
                        href={googlePreviewUrl}
                        gaObjectType={gaConfig.gaObjectType}
                        gaSelector={gaConfig.address}
                    />
                ) : (
                    <span>{practiceLocationAddress}</span>
                ),
            });
        }
        if (phones?.length) {
            phones.map((phone, idx) =>
                chips.push({
                    text: phone?.number,
                    customClass: 'phone',
                    icon: 'phone',
                    key: `phone-${idx}`,
                    extraInfo: phone?.description,
                    children: (
                        <CallNowBtn
                            colorTheme={colorTheme}
                            customTrigger={(onClick) => (
                                <ConcatenatedTextV2
                                    colorTheme={colorTheme}
                                    gaObjectType={gaConfig.gaObjectType}
                                    gaSelector={gaConfig.phone}
                                    customClass="no-underline"
                                    displayLink={`tel: ${phone?.number}`}
                                    expandText="Show phone number"
                                    isExpanded={false}
                                    key={phone.id}
                                    length={8}
                                    onConcatenation={onClick}
                                    text={phone?.number}
                                />
                            )}
                            modalContent={{
                                ...this.contactProps,
                                colorTheme,
                                hasSkin,
                                phoneNumber: phone?.number,
                            }}
                        />
                    ),
                }),
            );
        }
        if (fax?.id) {
            chips.push({
                icon: 'printer',
                text: fax?.number,
                extraInfo: fax?.description,
                children: (
                    <ConcatenatedTextV2
                        colorTheme={colorTheme}
                        customClass="no-underline"
                        displayLink={`tel: ${fax?.number}`}
                        expandText="Show fax number"
                        gaObjectType={gaConfig.gaObjectType}
                        gaSelector={gaConfig.fax}
                        // [TODO:REFACTOR] event name should be constant
                        onConcatenation={() => {
                            if (
                                this?.internalTrackingEvent?.faxClick
                                    ?.event === 'practiceLocationFaxClick'
                            ) {
                                trackPracticeLocationFaxClick(
                                    this.practice,
                                    fax?.number,
                                );
                            }
                            if (
                                this?.internalTrackingEvent?.faxClick
                                    ?.event === 'practitionerFaxClick'
                            ) {
                                // [TODO:REFACTOR] to use internalEventTracking
                                analytics.track('practitionerFaxClick', {
                                    practitioner: practiceId,
                                    practice_position: practicePositionId,
                                    phone_number: fax.number,
                                });
                            }
                        }}
                        length={8}
                        text={fax?.number}
                    />
                ),
            });
        }
        if (externalUrl) {
            chips.push({
                icon: 'share',
                children: (
                    <LinkV2
                        text="Visit website"
                        href={externalUrl}
                        gaObjectType={gaConfig.gaObjectType}
                        gaSelector={gaConfig.web}
                        onClick={() => {
                            // [TODO:REFACTOR] event name should be constant
                            if (
                                this?.internalTrackingEvent?.visitSite
                                    ?.event === 'openPracticeLocationWebsite'
                            ) {
                                trackOpenPracticeLocationWebsite(
                                    this.practice,
                                    externalUrl,
                                );
                            }
                        }}
                        target="_blank"
                    />
                ),
            });
        }
        return chips;
    }
    get collapseSectionsHeader() {
        const {localitySuburb, locality} = this.locationSectionActiveItem;
        const localityName = localitySuburb || locality?.name;
        const practiceName = this.locationSectionActiveItem?.name;
        return [practiceName, localityName]
            .filter((str) => str?.length)
            .join(', ');
    }
    get collapseSections() {
        const {
            extraInfo: {openHours, paymentOptions, serviceOptions, comment},
        } = this.locationSectionActiveItem;
        const openIndicator =
            this.locationSectionActiveItem.openIndicator ||
            this.locationSectionActiveItem.extraInfo?.openIndicator;
        const sections = [];
        if (openHours.length) {
            openHours.map(
                (daySched) =>
                    (daySched.day =
                        convertToDayNames[daySched.day.toLowerCase()]),
            );
            sections.push({
                header: `Opening Hours${
                    openIndicator ? ` (${openIndicator})` : ''
                }`.trim(),
                contents: [...openHours],
                extraInfo: comment,
                gaObjectType: gaConfig.gaObjectType,
                gaSelector: gaConfig.openingHours,
            });
        }

        if (paymentOptions.length) {
            sections.push({
                header: 'Payment Options',
                contents: [...paymentOptions],
                gaObjectType: gaConfig.gaObjectType,
                gaSelector: gaConfig.paymentOptions,
            });
        }

        if (serviceOptions) {
            sections.push({
                header: 'Service Options',
                contents: [...serviceOptions.split(',')],
                gaObjectType: gaConfig.gaObjectType,
                gaSelector: gaConfig.serviceOptions,
            });
        }

        return sections;
    }

    get pills() {
        const pills = [];
        const {disabledAccess, onsiteParking} = this.locationSectionActiveItem;
        if (disabledAccess) {
            pills.push({
                text: 'Disabled access',
                iconFront: 'wheelchair',
            });
        }

        if (onsiteParking) {
            pills.push({
                text: 'On-site parking',
                iconFront: 'parking',
            });
        }
        return pills;
    }

    @autobind
    async selectHealthFundHandler(hf) {
        await this.rootStore.healthFundStore.selectHealthFund(hf?.id || 0);

        if (this?.isHospitalPage) {
            const hospitalId = parseInt(
                window.sessionStorage.getItem('hospitalId'),
            );
            if (this.practice.id !== hospitalId) {
                window.sessionStorage.setItem('hospitalId', this.practice.id);
            }
            window.location.reload();
        }
    }

    @autobind
    isSpecialist(practitioner) {
        return !!practitioner.specialties.find(
            (specialty) => specialty.isSpecialist,
        );
    }

    @autobind
    profileCardCTA(practicePosition, objectType, contactType) {
        const {
            getCopyData,
            getContactModalContent,
            getRequestApptModalContent,
            practice,
            getBookingAvailability,
            isMh1Running,
            rootStore: {
                paramStore: {hasSkin, isProUser, isReferrals, dataSource},
                healthFundStore: {
                    enabledMH1BookingIntegration,
                    healthFundThemeColor,
                    isHealthFundBookingsEnabled,
                },
            },
        } = this;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;
        const displayHSReferralButton =
            getCopyData && isProUser && practice.referralToPractitioner;
        const hasEnabledMH1BookingIntegration = enabledMH1BookingIntegration(
            practicePosition,
        );
        const displayCustomBookNowBtn =
            isHealthFundBookingsEnabled &&
            !hasSkin &&
            practicePosition.customBookingDataUrl;
        const displayRequestApptBtn =
            isHealthFundBookingsEnabled &&
            !hasSkin &&
            practicePosition.requestAppointmentEmail;
        if (displayHSReferralButton)
            return (
                <HSReferralButtonV2
                    colorTheme={colorTheme}
                    getCopyData={() =>
                        getCopyData(practicePosition.displayName)
                    }
                    gaObjectType={objectType}
                />
            );
        else if (hasEnabledMH1BookingIntegration) {
            const availability = getBookingAvailability(
                hasEnabledMH1BookingIntegration,
            );

            return (
                <MH1BookNowBtn
                    dataSource={dataSource}
                    bookingIntegration={hasEnabledMH1BookingIntegration}
                    availability={availability}
                    isMh1Running={isMh1Running}
                    colorTheme={colorTheme}
                    gaObjectType={objectType}
                />
            );
        } else if (displayCustomBookNowBtn)
            return (
                <CustomBookingBtn
                    colorTheme={colorTheme}
                    gaObjectType={objectType}
                    customBookingDataUrl={
                        practicePosition.customBookingDataUrl
                    }
                />
            );
        else if (displayRequestApptBtn)
            return (
                <RequestApptBtn
                    requestApptEmail={practicePosition.requestAppointmentEmail}
                    colorTheme={colorTheme}
                    gaObjectType={objectType}
                    modalContent={{
                        isReferrals,
                        ...getRequestApptModalContent(practicePosition),
                    }}
                />
            );
        else
            return (
                <CallNowBtn
                    colorTheme={colorTheme}
                    gaObjectType={objectType}
                    modalContent={{
                        ...getContactModalContent({
                            ...practicePosition,
                            type: contactType,
                        }),
                    }}
                />
            );
    }

    @autobind
    async fetchPracticeInfo() {
        let practice = {};
        let availabilitySummary = [];
        try {
            const response = await http.get({
                url: this.url,
                data: {
                    show: this.rootStore.paramStore.showParam,
                },
            });
            practice = snakeToCamelObjectKeys(response);

            // fetch availability
            let practitionerIds = [];
            const mh1 = new Mh1BookingApi();
            // Profile
            if (practice?.locations && practice.locations.length) {
                practitionerIds = mh1.extractBookingIntegrationIds(
                    practice.locations,
                );
            }
            // Practice
            else if (
                practice?.practitioners &&
                practice?.practitioners.length
            ) {
                practitionerIds = mh1.extractBookingIntegrationIds(
                    practice.practitioners,
                );
            }

            if (practitionerIds.length) {
                availabilitySummary = await mh1.callAvailabilitySummary(
                    practitionerIds,
                );
            }

            if (typeof practice.offersTelehealth === 'string') {
                practice.offersTelehealth =
                    practice.offersTelehealth === 'pure' ||
                    practice.offersTelehealth === 'hybrid';
            }

            this.updateStore({
                isMh1Running: true,
            });
        } catch (error) {
            this.updateStore({
                isMh1Running: false,
            });
            this.throwFetchPracticeError(error);
        } finally {
            this.updateStore({
                practice,
                practiceLoaded: true,
                availabilitySummary,
            });
        }
    }

    @autobind
    async fetchPracticeRelatedContent() {
        let practiceRelatedContent = {};
        let response;
        try {
            response = await http.get({
                url: `${this.url}related-content/`,
            });
        } catch (error) {
            this.throwRelatedContentError(error);
        } finally {
            // replacement to .flat() which doesn't work on older browsers.
            if (response) {
                // for redesign we only need factsheets
                const {related_topics, ...factsheets} = response;
                const data = Object.values(factsheets);
                if ([].concat(...Object.values(data)).length) {
                    practiceRelatedContent = snakeToCamelObjectKeys(
                        factsheets,
                    );
                }
            }
            this.updateStore({
                practiceRelatedContent,
                practiceRelatedContentLoaded: true,
            });
        }
    }

    @autobind
    async fetchPracticeFAQs() {
        let practiceFAQs = [];
        try {
            const responseList = await http.get({
                url: `${this.url}faqs/`,
            });
            practiceFAQs = responseList.map((res) =>
                snakeToCamelObjectKeys(res),
            );
        } catch (error) {
            this.throwFAQError(error);
        } finally {
            this.updateStore({practiceFAQs});
        }
    }

    get loadFees() {
        const {
            healthFundStore: {healthFund},
            paramStore: {hasSkin},
        } = this.rootStore;
        //On a profile page
        if (!!this.userId) {
            return this.gapSchemeMembership?.length
                ? this.gapSchemeMembership[0].hasGapScheme
                : false;
        }
        return this.hasFees && (hasSkin || healthFund?.id > 0);
    }

    @autobind
    throwRelatedContentError(error) {
        throw new Error(`Fetching practice related content failed ${error}`);
    }

    @autobind
    throwFAQError(error) {
        throw new Error(`Fetching practice FAQs failed ${error}`);
    }

    @autobind
    throwFetchPracticeError(error) {
        throw new Error(`Fetching practice failed ${error}`);
    }
}
